<template>
  <div>
    <b-modal
      id="actionTypeModal"
      title="Вид действия"
      ok-only
      centered
      no-close-on-backdrop
      ok-title="Сохранить"
      @ok.prevent="onSubmit"
      @show="openShowModal"
      @hidden="onHide"
    >
      <b-list-group class="mb-1">
        <b-list-group-item
          v-for="(item, index) in actionType.results"
          :key="index"
          class="d-flex align-items-center justify-content-between"
        >
          <span>{{ item.name }}</span>
          <div class="d-flex align-items-center">
            <b-button
              variant="warning"
              class="p-25 mr-25"
              @click="onUpdate(item)"
            >
              <feather-icon
                icon="EditIcon"
                size="20"
              />
            </b-button>
            <b-button
              variant="danger"
              class="p-25"
              @click="onDelete(item.id)"
            >
              <feather-icon
                icon="Trash2Icon"
                size="20"
              />
            </b-button>
          </div>
        </b-list-group-item>
      </b-list-group>

      <b-form-group label="Вид документ">
        <b-form-input
          v-model="data.name"
        />
      </b-form-group>

      <b-pagination
        v-model="params.page"
        :total-rows="actionType.count"
        :per-page="params.page_size"
        first-number
        last-number
        align="center"
        prev-class="prev-item"
        next-class="next-item"
        class="mt-1 mb-0"
        @change="changePage"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  BListGroup,
  BListGroupItem,
  BModal,
  VBModal,
  BFormInput,
  BButton,
  BFormGroup, BPagination,
} from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import areYouSure from '@/mixins/areYouSure'

export default {
  name: 'Index',
  components: {
    BPagination,
    BModal,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BButton,
    BFormGroup,
  },
  directives: {
    'b-modal': VBModal,
  },
  mixins: [areYouSure],
  props: {
    type: {
      type: Boolean,
      default: false,
    },
    content_type_id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      data: {
        name: '',
      },
      isEdit: false,
      params: {
        page: 1,
        page_size: 10,
      },
    }
  },
  computed: {
    ...mapState('fileManagement', ['actionType']),
  },
  methods: {
    ...mapActions('reference', ['ADD_DOCUMENT_TYPE', 'FETCH_DOCUMENT_TYPES', 'EDIT_DOCUMENT_TYPE', 'DELETE_DOCUMENT_TYPE']),
    ...mapActions('fileManagement', ['FETCH_ACTION_TYPE_MODAL', 'FETCH_ACTION_TYPE_MODAL']),

    onUpdate(data) {
      this.data.name = data.name
      this.data.id = data.id
      this.isEdit = true
    },

    onDelete(id) {
      this.$_showAreYouSureModal().then(value => {
        if (value) {
          this.DELETE_DOCUMENT_TYPE(id)
            .then(() => {
              this.FETCH_DOCUMENT_TYPES({
                content_type_id: this.content_type_id,
                is_ssp: this.type,
              })
              this.$bvModal.hide('actionTypeModal')
              this.data = {
                name: '',
              }
              this.isEdit = false
            })
            .catch(() => {
              this.$_errorToast()
            })
        }
      })
    },

    onHide() {
      this.data = {
        name: '',
      }
      this.isEdit = false
      this.FETCH_ACTION_TYPE_MODAL({
        content_type_id: this.content_type_id,
        page_size: 500,
      })
    },

    async onSubmit() {
      try {
        if (!this.isEdit) {
          await this.ADD_DOCUMENT_TYPE({
            ...this.data,
            content_type_id: this.content_type_id,
            is_ssp: this.type,
          })
        } else {
          await this.EDIT_DOCUMENT_TYPE({
            ...this.data,
            content_type_id: this.content_type_id,
            is_ssp: this.type,
          })
        }
        await this.FETCH_ACTION_TYPE_MODAL({ page_size: 500, is_ssp: this.type })

        this.$bvModal.hide('actionTypeModal')
      } catch (error) {
        await this.$_errorToast()
        throw error
      }
    },

    openShowModal() {
      this.FETCH_ACTION_TYPE_MODAL({
        content_type_id: this.content_type_id,
        is_ssp: this.type,
      })
    },

    changePage(val) {
      this.params.page = val
      this.FETCH_ACTION_TYPE_MODAL({
        ...this.params,
        content_type_id: this.content_type_id,
        is_ssp: this.type,
      })
    },
  },
}
</script>
