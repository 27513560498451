<template>
  <div class="px-1">
    <b-list-group>
      <b-list-group-item class="d__flex">
        <span>Дата создания</span>
        <span>{{ docAttach.created_date }}</span>
      </b-list-group-item>

      <b-list-group-item class="d__flex">
        <span>Дата документа</span>
        <span>{{ docAttach.date_document }}</span>
      </b-list-group-item>

      <b-list-group-item class="d__flex">
        <span>Вид действия</span>
        <span>{{ docAttach.action_type && docAttach.action_type.name }}</span>
      </b-list-group-item>

      <b-list-group-item class="d__flex">
        <span>Вид документ</span>
        <b-badge
          v-for="(item, index) in docAttach.tags"
          :key="index"
          variant="primary"
        >
          {{ item.name }}
        </b-badge>
      </b-list-group-item>

      <b-list-group-item class="d__flex">
        <span>Файлы</span>
        <span>{{ docAttach.file }}</span>
      </b-list-group-item>

      <b-list-group-item class="d__flex">
        <span>Полезная информация</span>
        <span>{{ docAttach.title }}</span>
      </b-list-group-item>

      <b-list-group-item class="d__flex">
        <span>Комментарий</span>
        <span class="text-right">{{ docAttach.comment }}</span>
      </b-list-group-item>
    </b-list-group>

    <div class="d-flex flex-wrap p-1 mt-2 align-items-baseline">
      <div
        v-for="(item, index2) in docAttach.files"
        :key="index2"
        class="cursor-pointer d-flex flex-column justify-content-center mb-2 mr-2 position-relative text-center"
        style="width: 90px"
        @click="openDocFile(item)"
      >
        <img
          width="100px"
          height="100px"
          :src="checkFileImage(item.file)"
          class="rounded img__badge"
          alt="file-image"
        >
        <small>{{ item.file_title }}</small>
      </div>
    </div>

    <pdfTron :file-data="fileValue" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { BListGroup, BListGroupItem, BBadge } from 'bootstrap-vue'
import pdfTron from '@components/casesFileModal/modal.vue'

export default {
  components: {
    pdfTron,
    BListGroup,
    BBadge,
    BListGroupItem,
  },
  data() {
    return {
      fileValue: {},
    }
  },
  computed: {
    ...mapState('fileManagement', ['docAttach']),
  },
  methods: {
    openDocFile(value) {
      this.$nextTick(() => {
        this.fileValue = value
      })
      this.$bvModal.show('modalFileSecond')
    },

    checkFileImage(file) {
      if (/(jpg|png|jpeg|JPG)/.test(file)) {
        return file
      }
      if (/xls/.test(file)) {
        return require('@/assets/xls.png')
      }
      if (/(pdf|PDF)/.test(file)) {
        return require('@/assets/PDF.png')
      }
      if (/txt/.test(file)) {
        return require('@/assets/doubleFile.png')
      }

      return null
    },
  },
}
</script>
