<template>
  <b-sidebar
    id="fileSidebar"
    v-model="sidebar"
    bg-variant="white"
    backdrop
    right
  >
    <div v-if="docAttach.EDO">
      <div class="px-1">
        <b-list-group>
          <div class="d-flex align-items-center justify-content-end">
            <b-button
              size="sm"
              class="p-50 mb-50 ml-50 rounded-circle"
              variant="warning"
              @click="openIsEdit"
            >
              <feather-icon
                icon="EditIcon"
              />
            </b-button>

            <b-button
              size="sm"
              class="p-50 mb-50 ml-50 rounded-circle"
              variant="danger"
              @click="deleteAllInfo"
            >
              <feather-icon
                icon="TrashIcon"
              />
            </b-button>
          </div>

          <b-list-group-item class="d__flex">
            <span>Регистрационный номер</span>

            <b-form-input
              v-model="REG_NUMBER"
              plaintext
              class="text-right"
            />

          </b-list-group-item>
          <b-list-group-item class="d__flex">
            <span>Должник</span>
            <Lazy2
              v-show="!isEdit"
              v-model="BORROWER"
              :options="borrowerList2"
              :fetch="FETCH_BORROWER_LIST_LAZY"
              class="w-75 ml-1"
              :get-option-label="(v) => v.FULL_NAME || ''"
              @input="getOrg"
            />

            <span v-show="isEdit">
              {{ BORROWER && BORROWER.FULL_NAME }}
            </span>
          </b-list-group-item>
          <b-list-group-item class="d__flex">
            <span>Организация</span>
            <AppSelect
              v-if="!isEdit"
              v-model="ORGANIZATION"
              :disabled="!BORROWER"
              label="COMPANY_NAME"
              class="w-75 ml-1"
              :filters="orgId"
              :options="companyShortList"
              :lazy-fetch-data="BORROWER && BORROWER.id ? FETCH_COMPANY_SHORT_LIST : () => {}"
              @input="getDebt"
            />
            <span v-else>
              {{ ORGANIZATION && ORGANIZATION.COMPANY_NAME }}
            </span>
          </b-list-group-item>
          <b-list-group-item class="d__flex">
            <span>Долг</span>
            <AppSelect
              v-if="!isEdit"
              v-model="SK_DEBT"
              :disabled="!BORROWER"
              class="w-75 ml-1"
              label="DEBT_TYPE"
              :options="debtShortList"
              :filters="borrowerId"
              :lazy-fetch-data="ORGANIZATION ? FETCH_SK_DEBT_LIST_LAZY : () => {}"
              @input="getSkDebtFileType"
            />

            <span v-else>
              {{ SK_DEBT && SK_DEBT.DEBT_TYPE }}
            </span>
          </b-list-group-item>
          <b-list-group-item class="d__flex">
            <span>ДО, ИП</span>
            <AppSelect
              v-if="!isEdit"
              v-model="DEBT_OR_EXECUTIVE_WORK"
              :disabled="!SK_DEBT"
              class="w-75 ml-1"
              label="TITLE"
              :options="debtFileTypes"
              :filters="SK_DEBT"
              :lazy-fetch-data="SK_DEBT ? FETCH_DEBT_FILE_TYPES_LAZY : () => {}"
            />
            <span v-else>
              {{ DEBT_OR_EXECUTIVE_WORK && DEBT_OR_EXECUTIVE_WORK.TITLE }}
            </span>
          </b-list-group-item>
          <b-list-group-item class="d__flex">
            <span>Отправитель</span>
            <b-form-input
              v-model="value.SENDER"
              :plaintext="isEdit"
              :class="isEdit ? 'text-right' : 'w-100 ml-1'"
            />
          </b-list-group-item>
          <b-list-group-item class="d__flex">
            <span>Получен</span>

            <AppSelect
              v-if="!isEdit"
              v-model="RECEIVED_FROM"
              label="title"
              class="w-75 ml-1"
              title="title"
              :options="receivedFrom"
              :lazy-fetch-data="FETCH_RECEIVED_FROM_LAZY"
            />
            <span v-else>{{ RECEIVED_FROM && RECEIVED_FROM.title }}</span>

          </b-list-group-item>
          <b-list-group-item class="d__flex">
            <span>Наименование документа</span>
            <v-select
              v-if="!isEdit"
              v-model="value.TITLE"
              class="w-75 ml-1"
              label="name"
              :options="actionType.results"
            />
            <div
              v-else
              class="d-flex flex-wrap justify-content-end"
            >
              <span v-if="value.TITLE">{{ value.TITLE.name }}</span>
            </div>
          </b-list-group-item>
          <b-list-group-item class="d__flex">
            <span>Ответственный сотрудник</span>

            <AppSelect
              v-if="!isEdit"
              v-model="RESPONSIBLE"
              label="full_name"
              class="w-75 ml-1"
              title="full_name"
              :options="allUsers"
              :lazy-fetch-data="FETCH_ALL_USERS_LAZY"
            />
            <span v-else>{{ RESPONSIBLE && RESPONSIBLE.full_name }}</span>

          </b-list-group-item>
          <b-list-group-item class="d__flex">
            <span>Состояние</span>

            <AppSelect
              v-if="!isEdit"
              v-model="DOC_STATUS"
              label="title"
              class="w-75 ml-1"
              title="title"
              :options="docStatus"
              :lazy-fetch-data="FETCH_DOC_STATUS_LAZY"
            />
            <span v-else>{{ DOC_STATUS && DOC_STATUS.title }}</span>
          </b-list-group-item>
          <b-list-group-item
            v-if="!isEdit"
            class="d__flex"
          >
            <span>Файл</span>
            <b-form-file
              v-model="FILE"
              multiple
              drop-placeholder="Перетащите файл сюда"
              placeholder="Выберите файл"
              browse-text="Выбрать"
              class="w-75"
            />
          </b-list-group-item>
        </b-list-group>
      </div>

      <div class="d-flex flex-wrap p-1 mt-2 align-items-baseline">
        <div
          v-for="(item, index2) in docAttach.FILES && docAttach.FILES.files"
          :key="index2"
          class="position-relative cursor-pointer text-center mr-2 mb-2"
          style="width: 100px"
          @click.stop="openDocFile(item)"
        >
          <b-badge
            class="position__bbadge p-50"
            variant="danger"
            @click.stop="deleteFile(item.id, index2)"
          >
            <feather-icon icon="XIcon" />
          </b-badge>

          <img
            width="100px"
            height="100px"
            :src="checkFileImage(item.file)"
            class="rounded img__badge"
            alt="file-image"
          >
          <small>{{ item.file_title }}</small>
        </div>
      </div>

      <div
        v-if="!isEdit"
        class="d-flex align-items-center justify-content-end mt-1 mr-1 mb-2"
      >
        <b-button
          variant="danger"
          @click="isEdit = !isEdit"
        >
          Отмена
        </b-button>

        <b-button
          class="ml-1"
          variant="success"
          @click="onSubmit"
        >
          Сохранить
        </b-button>
      </div>
    </div>

    <IP v-else />

    <!--    @checkFileImage="checkFileImage"-->
    <!--    @deleteFile="deleteFile"-->
    <!--    @openDocFile="openDocFile"-->

    <pdfTron :file-data="fileValue" />
  </b-sidebar>
</template>

<script>
import {
  BBadge, BButton, BFormFile, BFormInput, BListGroup, BListGroupItem, BSidebar,
} from 'bootstrap-vue'
import { mapActions, mapMutations, mapState } from 'vuex'
import AppSelect from '@components/AppSelect.vue'
import pdfTron from '@/components/casesFileModal/modalFileSecond.vue'
import Lazy2 from '@components/lazy2.vue'
import VSelect from 'vue-select'
import IP from './IP.vue'

export default {
  components: {
    VSelect,
    Lazy2,
    AppSelect,
    BFormFile,
    BSidebar,
    BListGroup,
    BListGroupItem,
    BButton,
    BFormInput,
    BBadge,
    pdfTron,
    IP,
  },
  props: {
    fileData: {
      type: Object,
      default: () => ({}),
    },
    index: {
      type: Number,
      default: () => (0),
    },
  },
  data() {
    return {
      REG_NUMBER: null,
      sidebar: false,
      isEdit: true,
      FILE: [],
      RECEIVED_FROM: null,
      RESPONSIBLE: null,
      ORGANIZATION: {
        COMPANY_NAME: null,
        COMPANY_ID: null,
      },
      BORROWER: {
        FULL_NAME: null,
        id: null,
      },
      SK_DEBT: {},
      value: {
        SENDER: null,
        TITLE: {
          id: null,
          name: null,
        },
      },
      fileValue: {},
      companyId: {
        id: null,
      },
      borrowerId: {
        id: null,
      },
      debtId: {
        id: null,
      },
      orgId: {
        id: null,
      },
      DEBT_OR_EXECUTIVE_WORK: null,
      CONTENT_TYPE: null,
      OBJECT_ID: null,
      DOC_STATUS: null,
      ATTACHMENT_ID: null,
      ID: 0,
      timeOut: null,
    }
  },
  computed: {
    ...mapState('fileManagement', [
      'docStatus', 'allUsers', 'actionType', 'receivedFrom', 'docAttach', 'debtFiles', 'borrowerList2', 'debtFileTypes', 'companyShortList', 'debtShortList',
    ]),
  },
  watch: {
    'fileData.id': {
      immediate: true,
      handler() {
        if (this.sidebar && this.fileData && this.fileData.id) {
          this.timeOut = setTimeout(async () => {
            const {
              SENDER, TITLE, RECEIVED_FROM, RESPONSIBLE, ATTACHMENT_ID, DOC_STATUS, REG_NUMBER, ORGANIZATION, BORROWER, SK_DEBT, ID, DEBT_OR_EXECUTIVE_WORK,
            } = this.docAttach
            this.value = { SENDER, TITLE }
            this.RECEIVED_FROM = RECEIVED_FROM
            this.RESPONSIBLE = RESPONSIBLE
            this.ATTACHMENT_ID = ATTACHMENT_ID
            this.ID = ID
            this.ORGANIZATION = ORGANIZATION
            this.BORROWER = BORROWER
            this.SK_DEBT = SK_DEBT
            this.DEBT_OR_EXECUTIVE_WORK = DEBT_OR_EXECUTIVE_WORK
            this.companyId.id = ORGANIZATION ? ORGANIZATION.COMPANY_ID : ''
            this.debtId.id = SK_DEBT ? SK_DEBT.id : ''
            this.DOC_STATUS = DOC_STATUS
            this.REG_NUMBER = REG_NUMBER

            this.SET_EMPTY_DEBTFILE()
            this.SET_EMPTY_COMPANY_SHORT_LIST()
            this.CLEAR_SK_DEBT_LIST()

            this.FETCH_COMPANY_SHORT_LIST({ id: this.BORROWER.id })
            this.FETCH_DEBT_FILE_TYPES_LAZY({ id: SK_DEBT.id })
            this.FETCH_DEBT_SHORT_LIST({
              id: this.companyId.id,
              params: {
                borrower_id: this.BORROWER.id,
              },
            })
            this.FETCH_DEBT_FILE_TYPES_LAZY({ id: this.debtId.id })
            await this.FETCH_ACTION_TYPE_MODAL({ page_size: 500 })
          }, 500)
        }
      },
    },
    sidebar: {
      handler(val) {
        if (val === false) {
          this.SET_EMPTY_DEBTFILE()
          this.isEdit = true
        }
      },
    },
    BORROWER(val) {
      if (!val) {
        this.SET_EMPTY_DEBTFILE()
        this.ORGANIZATION = null
      }
    },
    ORGANIZATION(val) {
      if (!val) {
        this.SET_EMPTY_DEBTFILE()
        this.SK_DEBT = null
      }
    },
    SK_DEBT(val) {
      if (!val) {
        this.SET_EMPTY_DEBTFILE()
        this.DEBT_OR_EXECUTIVE_WORK = null
      }
    },
    isEdit: {
      immediate: true,
      handler() {
        this.SET_EMPTY_DEBTFILE()
      },
    },
  },
  methods: {
    ...mapActions('fileManagement', [
      'FETCH_DOC_STATUS_LAZY', 'FETCH_ALL_USERS_LAZY', 'FETCH_ACTION_TYPE_MODAL', 'FETCH_RECEIVED_FROM_LAZY', 'UPDATE_DOC_ATTACH',
      'DELETE_DOC_ATTACH', 'FETCH_BORROWER_LIST_LAZY', 'FETCH_SK_DEBT_LIST_LAZY', 'CLEAR_SK_DEBT_LIST', 'FETCH_DEBT_FILE_TYPES_LAZY',
      'DELETE_DOC_ATTACH_FILE', 'FETCH_COMPANY_SHORT_LIST', 'FETCH_DEBT_SHORT_LIST',
    ]),
    ...mapMutations('fileManagement', ['SET_EMPTY_COMPANY_SHORT_LIST', 'SET_EMPTY_DEBT_SHORT_LIST', 'SET_EMPTY_DEBTFILE']),

    onSubmit() {
      const formData = new FormData()
      this.FILE && Object.values(this.FILE).forEach(file => {
        if (file !== undefined) {
          formData.append('FILE', file)
        }
      })
      this.RECEIVED_FROM && formData.append('RECEIVED_FROM', this.RECEIVED_FROM.id)
      this.RESPONSIBLE && formData.append('RESPONSIBLE', this.RESPONSIBLE.id)
      this.ORGANIZATION && formData.append('ORGANIZATION', this.ORGANIZATION.COMPANY_ID)
      this.BORROWER && formData.append('BORROWER', this.BORROWER.id || this.BORROWER.BORROWER)
      this.SK_DEBT && formData.append('SK_DEBT', this.SK_DEBT.id)
      this.DEBT_OR_EXECUTIVE_WORK && formData.append('CONTENT_TYPE', this.DEBT_OR_EXECUTIVE_WORK.CONTENT_TYPE)
      this.DEBT_OR_EXECUTIVE_WORK && formData.append('OBJECT_ID', this.DEBT_OR_EXECUTIVE_WORK.OBJECT_ID)
      this.DOC_STATUS && formData.append('DOC_STATUS', this.DOC_STATUS.id)
      this.value?.SENDER && formData.append('SENDER', this.value.SENDER)
      this.value?.TITLE && formData.append('TITLE', this.value.TITLE.id)
      this.UPDATE_DOC_ATTACH({
        id: this.fileData.id,
        data: formData,
      })
        .then(() => {
          this.debtFiles.results = []
          this.$emit('refresh')
          this.sidebar = false
          this.isEdit = true
          this.$_okToast()
          this.FILE = []
        })
    },

    getOrg(val) {
      this.ORGANIZATION = null
      this.orgId.id = val && val.id
      this.SK_DEBT = null
      this.CLEAR_SK_DEBT_LIST()
      this.SET_EMPTY_COMPANY_SHORT_LIST()
      this.FETCH_COMPANY_SHORT_LIST({ id: val && val.id })
    },

    getDebt(id) {
      this.borrowerId.id = id.COMPANY_ID
      this.SK_DEBT = null
      this.SET_EMPTY_DEBT_SHORT_LIST()
      this.FETCH_DEBT_SHORT_LIST({
        id: id.COMPANY_ID,
        params: {
          borrower_id: this.BORROWER.id,
        },
      })
    },

    getSkDebtFileType({ id }) {
      this.SET_EMPTY_DEBTFILE()
      this.DEBT_OR_EXECUTIVE_WORK = null
      this.debtId.id = id
      this.FETCH_DEBT_FILE_TYPES_LAZY({ id })
    },

    deleteFile(id, index) {
      this.$_showAreYouSureModal()
        .then(value => {
          if (value) {
            this.DELETE_DOC_ATTACH(id)
              .then(() => {
                this.docAttach.FILES.files.splice(index, 1)
              })
          }
        })
    },

    deleteAllInfo() {
      this.$_showAreYouSureModal()
        .then(value => {
          if (value) {
            this.DELETE_DOC_ATTACH_FILE(this.ID)
              .then(() => {
                this.$_okToast()
                this.debtFiles.results.splice(this.index, 1)
                this.sidebar = false
              })
          }
        })
    },

    openDocFile(value) {
      this.$nextTick(() => {
        this.fileValue = value
      })
      this.$bvModal.show('modalFileSecond')
    },

    checkFileImage(file) {
      if (/(jpg|png|jpeg|JPG)/.test(file)) {
        return file
      }
      if (/xls/.test(file)) {
        return require('@/assets/xls.png')
      }
      if (/(pdf|PDF)/.test(file)) {
        return require('@/assets/PDF.png')
      }
      if (/txt/.test(file)) {
        return require('@/assets/doubleFile.png')
      }

      return null
    },

    openIsEdit() {
      this.SET_EMPTY_DEBTFILE()
      this.isEdit = !this.isEdit
    },
  },
}
</script>
