var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"addFile","title":"Документ","centered":"","size":"lg","no-close-on-backdrop":"","cancel-title":"Отмена","ok-title":"Сохранить"},on:{"show":_vm.openShowModal,"ok":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('validation-observer',{ref:"validationDoc"},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Должник"}},[_c('validation-provider',{attrs:{"name":"Должник","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Lazy2',{attrs:{"id":"model","options":_vm.borrowerList2,"fetch":_vm.FETCH_BORROWER_LIST_LAZY,"get-option-label":function (v) { return v.FULL_NAME || ''; }},on:{"input":_vm.getOrg},model:{value:(_vm.utils.BORROWER),callback:function ($$v) {_vm.$set(_vm.utils, "BORROWER", $$v)},expression:"utils.BORROWER"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Организация"}},[_c('validation-provider',{attrs:{"name":"Организация","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('AppSelect',{attrs:{"disabled":!_vm.utils.BORROWER,"label":"COMPANY_NAME","options":_vm.companyShortList,"filters":_vm.orgId,"lazy-fetch-data":_vm.utils.BORROWER && _vm.utils.BORROWER.id ? _vm.FETCH_COMPANY_SHORT_LIST : function () {}},on:{"input":_vm.getDebt},model:{value:(_vm.utils.ORGANIZATION),callback:function ($$v) {_vm.$set(_vm.utils, "ORGANIZATION", $$v)},expression:"utils.ORGANIZATION"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Долг"}},[_c('validation-provider',{attrs:{"name":"Долг","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('AppSelect',{attrs:{"disabled":!_vm.utils.BORROWER,"label":"DEBT_TYPE","options":_vm.debtShortList,"filters":_vm.borrowerId,"lazy-fetch-data":_vm.utils.ORGANIZATION && _vm.utils.ORGANIZATION.COMPANY_ID ? _vm.FETCH_SK_DEBT_LIST_LAZY : function () {}},on:{"input":_vm.getSkDebtFileType},model:{value:(_vm.utils.SK_DEBT),callback:function ($$v) {_vm.$set(_vm.utils, "SK_DEBT", $$v)},expression:"utils.SK_DEBT"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"ДО, ИП"}},[_c('validation-provider',{attrs:{"name":"ДО, ИП","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"disabled":!_vm.utils.SK_DEBT,"label":"TITLE","options":_vm.debtFileTypesModal.EXECUTIVE_WORK},on:{"input":function($event){return _vm.getEvent($event)}},model:{value:(_vm.executer),callback:function ($$v) {_vm.executer=$$v},expression:"executer"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Файл"}},[_c('b-form-file',{attrs:{"multiple":"","drop-placeholder":"Перетащите файл сюда","placeholder":"Выберите файл","browse-text":"Выбрать"},model:{value:(_vm.FILE),callback:function ($$v) {_vm.FILE=$$v},expression:"FILE"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Получен"}},[_c('validation-provider',{attrs:{"name":"Получен","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('AppSelect',{attrs:{"reduce":function (p) { return p.id; },"label":"title","title":"title","options":_vm.receivedFrom,"lazy-fetch-data":_vm.FETCH_RECEIVED_FROM_LAZY},model:{value:(_vm.utils.RECEIVED_FROM),callback:function ($$v) {_vm.$set(_vm.utils, "RECEIVED_FROM", $$v)},expression:"utils.RECEIVED_FROM"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Состояние"}},[_c('validation-provider',{attrs:{"name":"Состояние","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('AppSelect',{attrs:{"label":"title","title":"title","reduce":function (p) { return p.id; },"options":_vm.docStatus,"lazy-fetch-data":_vm.FETCH_DOC_STATUS_LAZY},model:{value:(_vm.utils.DOC_STATUS),callback:function ($$v) {_vm.$set(_vm.utils, "DOC_STATUS", $$v)},expression:"utils.DOC_STATUS"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Наименование документа"}},[_c('validation-provider',{attrs:{"name":"Наименование документа","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('v-select',{staticClass:"w-100",attrs:{"disabled":!_vm.executer,"label":"name","options":_vm.actionType.results},model:{value:(_vm.utils.TITLE),callback:function ($$v) {_vm.$set(_vm.utils, "TITLE", $$v)},expression:"utils.TITLE"}}),_c('b-button',{staticClass:"ml-50",attrs:{"disabled":!_vm.executer,"size":"sm","variant":"success"},on:{"click":_vm.openModal}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Ответственный сотрудник"}},[_c('validation-provider',{attrs:{"name":"Ответственный сотрудник","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('AppSelect',{attrs:{"label":"full_name","title":"full_name","options":_vm.allUsers,"lazy-fetch-data":_vm.FETCH_ALL_USERS_LAZY},model:{value:(_vm.utils.RESPONSIBLE),callback:function ($$v) {_vm.$set(_vm.utils, "RESPONSIBLE", $$v)},expression:"utils.RESPONSIBLE"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Отправитель"}},[_c('validation-provider',{attrs:{"name":"Отправитель","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.utils.SENDER),callback:function ($$v) {_vm.$set(_vm.utils, "SENDER", $$v)},expression:"utils.SENDER"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('actionTypeModal',{attrs:{"type":_vm.type,"content_type_id":_vm.content_type_id}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }