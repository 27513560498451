<template>
  <b-modal
    id="addFile"
    title="Документ"
    centered
    size="lg"
    no-close-on-backdrop
    cancel-title="Отмена"
    ok-title="Сохранить"
    @show="openShowModal"
    @ok.prevent="onSubmit"
  >
    <validation-observer ref="validationDoc">
      <b-row>
        <b-col md="6">
          <b-form-group label="Должник">
            <validation-provider
              #default="{ errors }"
              name="Должник"
              rules="required"
            >
              <Lazy2
                id="model"
                v-model="utils.BORROWER"
                :options="borrowerList2"
                :fetch="FETCH_BORROWER_LIST_LAZY"
                :get-option-label="(v) => v.FULL_NAME || ''"
                @input="getOrg"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Организация">
            <validation-provider
              #default="{ errors }"
              name="Организация"
              rules="required"
            >
              <AppSelect
                v-model="utils.ORGANIZATION"
                :disabled="!utils.BORROWER"
                label="COMPANY_NAME"
                :options="companyShortList"
                :filters="orgId"
                :lazy-fetch-data="utils.BORROWER && utils.BORROWER.id ? FETCH_COMPANY_SHORT_LIST : () => {}"
                @input="getDebt"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Долг">
            <validation-provider
              #default="{ errors }"
              name="Долг"
              rules="required"
            >
              <AppSelect
                v-model="utils.SK_DEBT"
                :disabled="!utils.BORROWER"
                label="DEBT_TYPE"
                :options="debtShortList"
                :filters="borrowerId"
                :lazy-fetch-data="utils.ORGANIZATION && utils.ORGANIZATION.COMPANY_ID ? FETCH_SK_DEBT_LIST_LAZY : () => {}"
                @input="getSkDebtFileType"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="ДО, ИП">
            <validation-provider
              #default="{ errors }"
              name="ДО, ИП"
              rules="required"
            >
              <v-select
                v-model="executer"
                :disabled="!utils.SK_DEBT"
                label="TITLE"
                :options="debtFileTypesModal.EXECUTIVE_WORK"
                @input="getEvent($event)"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Файл">
            <b-form-file
              v-model="FILE"
              multiple
              drop-placeholder="Перетащите файл сюда"
              placeholder="Выберите файл"
              browse-text="Выбрать"
            />
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group label="Получен">
            <validation-provider
              #default="{ errors }"
              name="Получен"
              rules="required"
            >
              <AppSelect
                v-model="utils.RECEIVED_FROM"
                :reduce="p => p.id"
                label="title"
                title="title"
                :options="receivedFrom"
                :lazy-fetch-data="FETCH_RECEIVED_FROM_LAZY"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Состояние">
            <validation-provider
              #default="{ errors }"
              name="Состояние"
              rules="required"
            >
              <AppSelect
                v-model="utils.DOC_STATUS"
                label="title"
                title="title"
                :reduce="p => p.id"
                :options="docStatus"
                :lazy-fetch-data="FETCH_DOC_STATUS_LAZY"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label="Наименование документа"
          >
            <validation-provider
              #default="{ errors }"
              name="Наименование документа"
              rules="required"
            >
              <div
                class="d-flex align-items-center"
              >
                <v-select
                  v-model="utils.TITLE"
                  :disabled="!executer"
                  class="w-100"
                  label="name"
                  :options="actionType.results"
                />
                <b-button
                  :disabled="!executer"
                  class="ml-50"
                  size="sm"
                  variant="success"
                  @click="openModal"
                >
                  <feather-icon icon="PlusIcon" />
                </b-button>
              </div>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Ответственный сотрудник">
            <validation-provider
              #default="{ errors }"
              name="Ответственный сотрудник"
              rules="required"
            >
              <AppSelect
                v-model="utils.RESPONSIBLE"
                label="full_name"
                title="full_name"
                :options="allUsers"
                :lazy-fetch-data="FETCH_ALL_USERS_LAZY"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Отправитель">
            <validation-provider
              #default="{ errors }"
              name="Отправитель"
              rules="required"
            >
              <b-form-input v-model="utils.SENDER" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </validation-observer>

    <actionTypeModal
      :type="type"
      :content_type_id="content_type_id"
    />
  </b-modal>
</template>

<script>
import {
  BModal,
  BFormGroup,
  BFormInput,
  BFormFile,
  BRow,
  BCol, BButton,
} from 'bootstrap-vue'
import { mapActions, mapMutations, mapState } from 'vuex'
import AppSelect from '@components/AppSelect.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import Lazy2 from '@components/lazy2.vue'
import VSelect from 'vue-select'
import actionTypeModal from './actionTypeModal.vue'

export default {
  components: {
    actionTypeModal,
    BButton,
    BModal,
    BFormGroup,
    BFormInput,
    BFormFile,
    BRow,
    BCol,
    AppSelect,
    ValidationObserver,
    ValidationProvider,
    Lazy2,
    VSelect,
  },
  data() {
    return {
      required,
      utils: {
        SENDER: null,
        TITLE: {
          id: null,
          name: '',
        },
        RECEIVED_FROM: null,
        RESPONSIBLE: {
          username: null,
          id: null,
        },
        DOC_STATUS: null,
        ORGANIZATION: {
          COMPANY_NAME: null,
          COMPANY_ID: null,
        },
        BORROWER: {
          id: null,
        },
        SK_DEBT: null,
        CONTENT_TYPE: null,
        OBJECT_ID: null,
      },
      executer: {
        OBJECT_ID: null,
        CONTENT_TYPE: null,
      },
      orgId: {
        id: null,
      },
      borrowerId: {
        id: null,
      },
      debtId: {
        id: null,
      },
      FILE: [],
      type: null,
      content_type_id: 0,
    }
  },
  computed: {
    ...mapState('fileManagement', [
      'docStatus', 'allUsers', 'receivedFrom', 'borrowerList2', 'debtFileTypesModal', 'debtFiles', 'companyShortList',
      'debtShortList', 'actionType',
    ]),
  },
  watch: {
    'utils.BORROWER': function (val) {
      if (!val) {
        this.utils.ORGANIZATION = null
        this.SET_EMPTY_DEBT_SHORT_LIST()
      }
    },
    'utils.ORGANIZATION': function (val) {
      if (!val) {
        this.utils.SK_DEBT = null
        this.SET_EMPTY_DEBT_SHORT_LIST()
      } else {
        this.FETCH_DEBT_SHORT_LIST({
          id: val.COMPANY_ID,
          params: {
            borrower_id: this.utils.BORROWER.id,
          },
        })
      }
    },
    'utils.SK_DEBT': function (val) {
      if (!val) {
        this.executer = null
      }
    },
    debtShortList: {
      handler(val) {
        if (val.count === 1) {
          this.utils.SK_DEBT = val.results && val.results[0]
          this.FETCH_DEBT_FILE_TYPES_LAZY_MODAL({ id: val.results && val.results[0]?.id })
        }
      },
    },
    debtFileTypesModal: {
      handler(val) {
        if (val.EXECUTIVE_WORK && val.EXECUTIVE_WORK.length === 1) {
          [this.executer] = val.EXECUTIVE_WORK

          this.FETCH_ACTION_TYPE_MODAL({
            content_type_id: val?.CONTENT_TYPE,
            page_size: 500,
          }).then(() => {
            this.type = this.actionType.results && this.actionType.results[0] && this.actionType.results[0].is_ssp
            this.content_type_id = val?.CONTENT_TYPE
          })
        }
      },
    },
  },
  methods: {
    ...mapActions('fileManagement', [
      'CREATE_DOC_ATTACH', 'FETCH_DOC_STATUS_LAZY', 'FETCH_ALL_USERS_LAZY', 'FETCH_RECEIVED_FROM_LAZY', 'FETCH_BORROWER_LIST_LAZY', 'FETCH_SK_DEBT_LIST_LAZY',
      'CLEAR_SK_DEBT_LIST', 'FETCH_DEBT_FILE_TYPES_LAZY_MODAL', 'CLEAR_DEBT_FILE_TYPES', 'FETCH_DEBT_FILES', 'FETCH_COMPANY_SHORT_LIST', 'FETCH_DEBT_SHORT_LIST', 'FETCH_ACTION_TYPE_MODAL',
    ]),
    ...mapMutations('fileManagement', ['SET_EMPTY_COMPANY_SHORT_LIST', 'SET_EMPTY_DEBT_SHORT_LIST']),

    getOrg(val) {
      this.orgId.id = val?.id
      this.utils.SK_DEBT = null
      this.utils.ORGANIZATION = null
      this.executer = null
      this.CLEAR_SK_DEBT_LIST()
      this.CLEAR_DEBT_FILE_TYPES()
      this.SET_EMPTY_COMPANY_SHORT_LIST()
      this.FETCH_COMPANY_SHORT_LIST({ id: val?.id })
        .then(res => {
          if (res.count === 1) {
            this.utils.ORGANIZATION = res.results && res.results[0]
          }
        })
    },

    getDebt(id) {
      this.borrowerId.id = id.COMPANY_ID
      this.utils.SK_DEBT = null
      this.SET_EMPTY_DEBT_SHORT_LIST()
      this.CLEAR_DEBT_FILE_TYPES()
      this.FETCH_DEBT_SHORT_LIST({
        id: id.COMPANY_ID,
        params: {
          borrower_id: this.utils.BORROWER.id,
        },
      })
    },

    getSkDebtFileType(id) {
      this.debtId.id = id
      this.executer = null
      this.CLEAR_DEBT_FILE_TYPES()
      this.FETCH_DEBT_FILE_TYPES_LAZY_MODAL({ id })
    },

    onSubmit() {
      const formData = new FormData()
      for (const key in this.FILE) {
        if (this.FILE.hasOwnProperty(key)) {
          const file = this.FILE[key]
          if (typeof file !== 'undefined') {
            formData.append('FILE', file)
          }
        }
      }

      this.utils.ORGANIZATION && formData.append('ORGANIZATION', this.utils.ORGANIZATION.COMPANY_ID)
      this.utils.BORROWER && formData.append('BORROWER', this.utils.BORROWER.id)
      this.utils.SK_DEBT && formData.append('SK_DEBT', this.utils.SK_DEBT?.id)
      this.executer && formData.append('CONTENT_TYPE', this.executer.CONTENT_TYPE)
      this.executer && formData.append('OBJECT_ID', this.executer.OBJECT_ID)
      this.utils.SENDER && formData.append('SENDER', this.utils.SENDER)
      this.utils.TITLE && formData.append('TITLE', this.utils.TITLE.id)
      this.utils.RECEIVED_FROM && formData.append('RECEIVED_FROM', this.utils.RECEIVED_FROM)
      this.utils.RESPONSIBLE && formData.append('RESPONSIBLE', this.utils.RESPONSIBLE.id)
      this.utils.DOC_STATUS && formData.append('DOC_STATUS', this.utils.DOC_STATUS)
      this.$refs.validationDoc.validate()
        .then(success => {
          if (success && this.FILE && this.FILE.length) {
            this.CREATE_DOC_ATTACH(formData)
              .then(() => {
                this.$bvModal.hide('addFile')
                this.debtFiles.results = []
                this.FETCH_DEBT_FILES({
                  object_id: this.executer.OBJECT_ID,
                  content_type: this.executer.CONTENT_TYPE,
                })
                this.openShowModal()
                this.$_okToast()
              })
          } else {
            this.$bvModal.msgBoxConfirm('Пожалуйста, заполните поле файл!', {
              title: 'Пожалуйста подтвердите',
              size: 'sm',
              okVariant: 'primary',
              okTitle: 'Хорошо',
              cancelTitle: 'Отмена',
              cancelVariant: 'outline-secondary',
              hideHeaderClose: false,
              centered: true,
            })
          }
        })
    },

    async openShowModal() {
      this.FILE = []
      this.SENDER = null
      this.RECEIVED_FROM = null
      this.RESPONSIBLE = null
      this.DOC_STATUS = null
      this.utils = {
        SENDER: null,
        TITLE: null,
        RECEIVED_FROM: null,
        RESPONSIBLE: null,
        DOC_STATUS: null,
        ORGANIZATION: null,
        BORROWER: null,
        SK_DEBT: null,
        CONTENT_TYPE: null,
        OBJECT_ID: null,
      }
      this.borrowerId = {
        id: null,
      }
      this.debtId = {
        id: null,
      }
      this.executer = null
    },

    openModal() {
      this.$nextTick(() => {
        this.$bvModal.show('actionTypeModal')
      })
    },

    getEvent(value) {
      if (value) {
        this.FETCH_ACTION_TYPE_MODAL({
          content_type_id: value?.CONTENT_TYPE,
          page_size: 500,
        }).then(() => {
          this.type = this.actionType.results && this.actionType.results[0] && this.actionType.results[0].is_ssp
          this.content_type_id = value?.CONTENT_TYPE
        })
      }
    },
  },
}
</script>
