<template>
  <div>
    <breadcrumb :company="company" />

    <b-card
      no-body
      class="mb-50 p-1"
    >
      <b-row class="change__col">
        <b-col md="3">
          <b-form-input
            v-model="search"
            class="mb-50"
            type="search"
            placeholder="Поиск по портфелям"
          />
        </b-col>

        <b-col md="3">
          <b-form-input
            v-model="params.search_document"
            placeholder="Поиск по должник"
            type="search"
            @input="filteredData($event, 'search')"
          />
        </b-col>

        <b-col md="3">
          <v-select
            v-model="params.title"
            placeholder="Наименование документа"
            label="name"
            multiple
            :close-on-select="false"
            :reduce="el => el.id"
            :options="actionType.results"
            @input="filteredData($event, 'title')"
          />
        </b-col>

        <b-col md="3">
          <AppSelect
            v-model="params.received_from"
            :placeholder="'Получен'"
            :reduce="p => p.id"
            label="title"
            title="title"
            multiple
            :close-on-select="false"
            :options="receivedFrom"
            :lazy-fetch-data="FETCH_RECEIVED_FROM_LAZY"
            @input="filteredData($event, 'received_from')"
          />
        </b-col>
        <b-col md="3">
          <AppSelect
            v-model="params.responsible"
            :placeholder="'Ответственный сотрудник'"
            label="full_name"
            title="full_name"
            multiple
            :close-on-select="false"
            :reduce="el => el.id"
            :options="allUsers"
            :lazy-fetch-data="FETCH_ALL_USERS_LAZY"
            @input="filteredData($event, 'responsible')"
          />
        </b-col>

        <b-col md="3">
          <AppSelect
            v-model="params.doc_status"
            :placeholder="'Состояние'"
            label="title"
            title="title"
            multiple
            :close-on-select="false"
            :reduce="p => p.id"
            :options="docStatus"
            :lazy-fetch-data="FETCH_DOC_STATUS_LAZY"
            @input="filteredData($event, 'doc_status')"
          />
        </b-col>
        <b-col md="3">
          <VcDatePicker
            ref="birthdayRef"
            v-model="params.date"
            show-format="DD.MM.YYYY"
            :other-props="{ 'is-range': true }"
            placeholder="DD.MM.YYYY"
            @input="filteredData($event)"
          />
        </b-col>

        <b-col
          md="3"
        >
          <div class="d-flex flex-wrap justify-content-end ml-auto align-items-baseline file__manager">
            <b-button
              class="mr-50  d-flex align-items-center"
              @click="clearFilters"
            >
              <feather-icon icon="XIcon" />
              <span>Очистить</span>
            </b-button>

            <b-button
              variant="success"
              class="d-flex align-items-center"
              @click="exportExcel"
            >
              <feather-icon icon="DownloadIcon" />
              <span class="inline-text">Скачать в Excel</span>
            </b-button>

            <b-button
              v-b-modal:addFile
              variant="primary"
              class="d-flex align-items-center ml-50"
              @click="clearFilters"
            >
              <feather-icon icon="PlusIcon" />
              <span>Добавить</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card>

    <b-card>
      <b-row>
        <b-col md="3">
          <b-overlay
            :show="spin"
            :opacity="0.5"
            spinner-variant="primary"
          >
            <div
              style="height: 600px"
              class="overflow-auto"
            >
              <LazyScroll
                v-if="debtClients.count"
                :other-params="{
                  page_size: 5,
                }"
                :store-fetch="getClients"
                :store-list="debtClients"
              >
                <div
                  v-for="(item, index) in debtClients.results"
                  :key="index"
                  class="treenode__css2 mb-50 mr-50"
                  :class="activeIndex === item.COMPANY_ID ? 'bg-primary text-white' : ''"
                  @click="getBorrowerList(item, index)"
                >
                  <div class="d_flex2 p-50">
                    <img
                      height="20"
                      :src="require('@/assets/doc.png')"
                      alt="#"
                    >
                    <p class="ml-50">
                      {{ item.COMPANY_NAME }}
                    </p>
                  </div>
                </div>
              </LazyScroll>
              <p
                v-else
                class="empty__file"
              >
                <empty />
              </p>
            </div>
          </b-overlay>
        </b-col>

        <b-col md="9">
          <b-overlay
            :opacity="0.5"
            :show="borrowSpin"
            spinner-variant="primary"
          >
            <Files
              v-if="organization.COMPANY_ID"
              :file="file"
              :organization="organization"
              :borrow-id="borrowId"
              :next-br="nextBr"
              :filters="params"
              @backToFile="backToFile"
            />
          </b-overlay>
        </b-col>
      </b-row>
    </b-card>

    <addFile />
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BOverlay,
} from 'bootstrap-vue'
import { mapActions, mapMutations, mapState } from 'vuex'
import AppSelect from '@components/AppSelect.vue'
import LazyScroll from '@components/LazyScroll/LazyScroll.vue'
import breadcrumb from '@/modules/FileManagement/views/breadcrumb.vue'
import Files from '@/modules/FileManagement/components/Files/file.vue'
import '../components/file.css'
import empty from '@/components/empty.vue'
import VSelect from 'vue-select'
import addFile from '../components/Files/modals/addFile.vue'

export default {
  components: {
    VSelect,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BOverlay,
    AppSelect,
    LazyScroll,
    breadcrumb,
    Files,
    addFile,
    empty,
  },
  data() {
    return {
      from_to_date: '',
      spin: false,
      activeIndex: null,
      file: true,
      borrowId: 0,
      organization: {},
      search: null,
      params: {
        date: {},
        search_document: '',
        received_from: [],
        responsible: [],
        doc_status: [],
        title: [],
      },
      timeOut: null,
      company: '',
      nextBr: false,
    }
  },
  computed: {
    ...mapState('fileManagement', [
      'debtClients', 'borrowerList', 'actionType', 'receivedFrom', 'allUsers', 'docStatus', 'breadcrumb',
      'step', 'activeBorrow', 'activeFileType', 'debtFiles2', 'borrowSpin',
    ]),
  },
  watch: {
    params: {
      handler(val) {
        this.spin = true
        clearTimeout(this.timeOut)
        this.timeOut = setTimeout(async () => {
          this.CLEAR_CLIENT()
          await this.FETCH_DEBT_CLIENTS(this.toQueryParams(this.params))
          this.spin = false

          if (this.step === 1) {
            const { search_document, ...rest } = this.params
            const newParams = {
              search: search_document,
              ...rest,
            }
            await this.FETCH_BORROWER_LIST({
              id: this.organization.COMPANY_ID,
              params: this.toQueryParams(newParams),
            })
          } else if (this.step === 2) {
            await this.FETCH_SK_DEBT_LIST({
              id: this.activeBorrow,
              params: this.toQueryParams(this.params),
            })
          } else if (this.step === 3) {
            await this.FETCH_DEBT_FILE_TYPES({
              id: this.activeBorrow,
              params: this.toQueryParams(this.params),
            })
          } else if (this.step === 4) {
            await this.FETCH_DEBT_FILES(this.toQueryParams({ ...this.debtFiles2, ...this.params }))
          }
        }, 500)
      },

      deep: true,
    },

    search: {
      handler(val) {
        clearTimeout(this.timeOut)
        this.timeOut = setTimeout(() => {
          this.FETCH_DEBT_CLIENTS({
            search: val,
            page_size: 15,
            page: 1,
            search_document: this.params.search_document,
          })
        }, 500)
      },
    },

    step: {
      handler(val) {
        if (this.step !== 0) {
          this.SET_BORROW_SPIN(true)
        }
        const commonParams = {
          page_size: 15,
          page: 1,
          search: this.params.search_document,
          received_from: this.params.received_from,
          responsible: this.params.responsible,
          doc_status: this.params.doc_status,
          title: this.params.title,
          created_date: this.params.date?.start,
          end_date: this.params.date?.end,
        }

        if (val === 1) {
          // this.FETCH_BORROWER_LIST({
          //   id: this.organization.COMPANY_ID,
          //   params: this.toQueryParams(commonParams),
          // })
          //   .finally(() => {
          this.SET_BORROW_SPIN(false)
          //   })
        }

        if (val === 2) {
          this.FETCH_SK_DEBT_LIST({
            id: this.activeBorrow,
            params: this.toQueryParams(commonParams),
          })
            .finally(() => {
              this.SET_BORROW_SPIN(false)
            })
        }

        if (val === 3) {
          this.FETCH_DEBT_FILE_TYPES({
            id: this.activeFileType,
            params: this.toQueryParams(commonParams),
          })
            .finally(() => {
              this.SET_BORROW_SPIN(false)
            })
        }

        if (val === 4) {
          this.FETCH_DEBT_FILES(this.toQueryParams({
            ...this.debtFiles2,
            ...this.params,
          }))
            .finally(() => {
              this.SET_BORROW_SPIN(false)
            })
        }
      },
    },
  },
  mounted() {
    this.getClients()
    this.FETCH_ACTION_TYPE_MODAL({ page_size: 500 })
  },
  methods: {
    ...mapActions('fileManagement', [
      'FETCH_DEBT_CLIENTS', 'FETCH_BORROWER_LIST', 'FETCH_ACTION_TYPE_MODAL', 'FETCH_RECEIVED_FROM_LAZY',
      'FETCH_ALL_USERS_LAZY', 'FETCH_DOC_STATUS_LAZY', 'FETCH_SK_DEBT_LIST', 'FETCH_DEBT_FILE_TYPES', 'FETCH_DEBT_FILES', 'CLEAR_CLIENT', 'CLEAR_BORROWER_LIST', 'FETCH_DEBT_EXCEL_ARCHIVE',
    ]),
    ...mapMutations('fileManagement', ['SET_ARRAY', 'SET_FILTERS', 'SET_STEP', 'SET_FILTERS_EMPTY', 'SET_EMPTY_BORROWER', 'SET_BORROW_SPIN', 'SET_EMPTY_DEBTFILE', 'CLEAR_SK_DEBT_LIST', 'SET_EMPTY_DEBT_FILES', 'SET_PARAMS']),

    async getClients() {
      try {
        this.spin = true
        const params = {
          page_size: 15,
          page: this.debtClients.next ? this.debtClients.next : null,
          search: this.search,
          ...this.params,
        }
        await this.FETCH_DEBT_CLIENTS(params)
      } catch (e) {
        await this.$_errorToast(e)
        this.spin = false
      }
      this.spin = false
    },

    async getBorrowerList(value) {
      try {
        this.nextBr = false
        this.SET_BORROW_SPIN(true)
        this.SET_STEP(1)

        const commonParams = {
          page_size: 15,
          page: 1,
          search: this.params.search_document,
          received_from: this.params.received_from,
          responsible: this.params.responsible,
          doc_status: this.params.doc_status,
          title: this.params.title,
          created_date: this.params?.date?.start,
          end_date: this.params?.date?.end,
        }

        this.organization = value
        this.activeIndex = value.COMPANY_ID
        this.borrowerList.results = []
        this.file = true
        await this.FETCH_BORROWER_LIST({
          id: value.COMPANY_ID,
          params: this.toQueryParams(commonParams),
        })
        this.nextBr = true
        this.company = value.COMPANY_NAME
        this.SET_ARRAY([])
        this.borrowId = value.COMPANY_ID
      } catch (e) {
        await this.$_errorToast(e)
        this.SET_BORROW_SPIN(false)
      }
      this.SET_BORROW_SPIN(false)
    },

    backToFile() {
      this.SET_STEP(2)
      this.file = !this.file
      if (this.file) {
        this.breadcrumb.splice(this.breadcrumb.length - 1, 1)
        this.SET_STEP(1)
      }
    },

    toQueryParams(params) {
      const stepFunctions = {
        1: this.SET_EMPTY_BORROWER,
        2: this.CLEAR_SK_DEBT_LIST,
        3: this.SET_EMPTY_DEBTFILE,
        4: this.SET_EMPTY_DEBT_FILES,
      }

      const stepFunction = stepFunctions[this.step]
      if (stepFunction) {
        stepFunction()
      }
      const searchParams = new URLSearchParams()
      searchParams.append('page', 1)
      searchParams.append('page_size', 15)

      Object.entries(params).forEach(([key, values]) => {
        if (Array.isArray(values)) {
          if (values.length) {
            values.forEach(value => {
              searchParams.append(key, value)
            })
          }
        }

        if (this.step === 0 || this.step === 1) {
          if (key === 'search_document') {
            searchParams.append('search_document', params.search_document || '')
          }
          if (key === 'search') {
            searchParams.append('search', params.search || '')
          }
        }
      })

      if (Object.keys(params).some(key => key.includes('date'))) {
        if (this.params.date?.start) {
          searchParams.append('created_date', this.params.date?.start || '')
          searchParams.append('end_date', this.params.date?.end || '')
        }
      }

      if (Object.keys(params).some(key => key.includes('t_id'))) {
        searchParams.append('object_id', params?.object_id)
        searchParams.append('content_type', params.content_type)
      }

      return searchParams
    },

    filteredData() {
      this.SET_PARAMS(this.params)
      clearTimeout(this.timeOut)
      this.timeOut = setTimeout(async () => {
        const hasFilters = Object.values(this.params).some(value => value !== null)
        const commonParams = {
          page_size: 15,
          page: 1,
          search_document: this.step == 0 || this.step == 1 ? this.params.search_document : null,
          received_from: this.params.received_from,
          responsible: this.params.responsible,
          doc_status: this.params.doc_status,
          title: this.params.title,
          created_date: this.params?.start,
          end_date: this.params?.end,
        }

        const commonParams1 = {
          page_size: 15,
          page: 1,
          search: this.step == 0 || this.step == 1 ? this.params.search_document : null,
          received_from: this.params.received_from,
          responsible: this.params.responsible,
          doc_status: this.params.doc_status,
          title: this.params.title,
          created_date: this.params?.start,
          end_date: this.params?.end,
        }

        const emptyParams = {
          page_size: 15,
          page: 1,
        }

        if (hasFilters) {
          this.SET_FILTERS(this.params)

          let part = this.step === 1 || this.step === 2 || this.step === 3 || this.step === 4 ? commonParams1 : commonParams
          part = this.toQueryParams(part)
          if (part) {
            this.SET_BORROW_SPIN(true)
          } else {
            this.SET_BORROW_SPIN(false)
          }
          switch (this.step) {
            case 0:
              // this.CLEAR_CLIENT()
              // await this.FETCH_DEBT_CLIENTS(part)
              // .finally(() => {
              this.SET_BORROW_SPIN(false)
              // })
              break
            case 1:
              // if (this.params.search_document) {
              // this.CLEAR_CLIENT()
              // await this.FETCH_DEBT_CLIENTS(
              //   this.toQueryParams({
              //     page: 1,
              //     page_size: 15,
              //     search_document: this.params.search_document,
              //     received_from: this.params.received_from,
              //     responsible: this.params.responsible,
              //     doc_status: this.params.doc_status,
              //     title: this.params.title,
              //   })
              // )
              //   .finally(() => {
              this.SET_BORROW_SPIN(false)
              // })
              this.FETCH_BORROWER_LIST({
                id: this.organization.COMPANY_ID,
                params: part,
              })
                .finally(() => {
                  this.SET_BORROW_SPIN(false)
                })
              break
            case 2:
              await this.FETCH_SK_DEBT_LIST({
                id: this.activeBorrow,
                params: part,
              })
                .finally(() => {
                  this.SET_BORROW_SPIN(false)
                })
              break
            case 3:
              await this.FETCH_DEBT_FILE_TYPES({
                id: this.activeFileType,
                params: part,
              })
                .finally(() => {
                  this.SET_BORROW_SPIN(false)
                })
              break
            case 4:
              // await this.FETCH_DEBT_FILES({ ...part, ...this.debtFiles2 })
              //   .finally(() => {
              //     this.SET_BORROW_SPIN(false)
              //   })
              break
          }
        } else {
          if (this.step === 0) {
            await this.getClients()
          } else if (this.step === 1) {
            await this.getClients()
            await this.FETCH_BORROWER_LIST({
              id: this.organization.COMPANY_ID,
              params: { page: 1, ...emptyParams },
            })
          } else if (this.step === 2) {
            await this.FETCH_SK_DEBT_LIST({
              id: this.activeBorrow,
              params: emptyParams,
            })
          } else if (this.step === 3) {
            await this.FETCH_DEBT_FILE_TYPES({
              id: this.activeFileType,
              params: emptyParams,
            })
          } else if (this.step === 4) {
            await this.FETCH_DEBT_FILES({ ...emptyParams, ...this.debtFiles2 })
          }

          this.SET_FILTERS_EMPTY()
        }
      }, 500)
    },

    async exportExcel() {
      try {
        const { date, ...rest } = this.params
        await this.FETCH_DEBT_EXCEL_ARCHIVE({ created_date: date.start, end_date: date.end })
          .then(res => {
            const url = window.URL.createObjectURL(new Blob([res]))
            const link = document.createElement('a')
            link.href = url
            const today = new Date()
            const day = today.getDate() < 10 ? `0${today.getDate()}` : today.getDate()
            const month = (today.getMonth() + 1) < 10 ? `0${today.getMonth() + 1}` : (today.getMonth() + 1)
            const year = today.getFullYear()
            const hour = today.getHours() < 10 ? `0${today.getHours()}` : today.getHours()
            const minute = today.getMinutes() < 10 ? `0${today.getMinutes()}` : today.getMinutes()

            const formattedDate = `${day}.${month}.${year}/${hour}-${minute}`
            link.setAttribute('download', `ЭДО_${formattedDate}.xls`)

            document.body.appendChild(link)
            link.click()
          })
      } catch (error) {
        await this.$_errorToast()
      }
    },

    clearFilters() {
      this.spin = false
      this.SET_BORROW_SPIN(false)
      this.borrowerList.results = []
      this.SET_ARRAY([])
      this.SET_STEP(0)
      this.SET_FILTERS_EMPTY()
      this.SET_EMPTY_BORROWER()
      this.params = {}
      this.organization = {}
      this.search = null
      this.activeIndex = null
      this.file = true
      this.nextBr = false
      this.company = ''
      // this.FETCH_DEBT_CLIENTS({
      //   page: 1,
      //   page_size: 15,
      // })
    },
  },
}
</script>

<style>
.inline-text {
  white-space: nowrap;
}
</style>
