<template>
  <b-overlay
    :show="loading"
    spinner-variant="primary"
    rounded="sm"
  >
    <div class="mb-1 d-flex align-items-center justify-content-between">
      <b-button
        variant="secondary"
        @click="$emit('utilsFile')"
      >
        <feather-icon icon="CornerLeftUpIcon" />
        Назад
      </b-button>
    </div>

    <div
      :class="!list ? '' : 'd-flex flex-wrap'"
    >
      <LazyScroll
        v-if="debtFiles.count"
        :other-params="{
          page_size: 5,
        }"
        :class="!list ? '' : 'd-flex flex-wrap'"
        :store-fetch="getDebtFiles"
        :store-list="debtFiles"
      >
        <div
          v-for="(item, index1) in debtFiles.results"
          :key="index1"
          :class="!list ? 'listStyle rounded p-50 ' : 'file__image p-50 mr-2'"
          @click="openDetailFile(item, index1)"
        >
          <img
            class="img-fluid"
            :src="require('@/assets/images/file.png')"
            alt="file-image"
          >
          <small v-if="item.title">{{ item.title }}</small>
        </div>
      </LazyScroll>
      <p
        v-else
        class="empty__file position-absolute position-bottom-0"
      >
        <empty />
      </p>
    </div>

    <fileSidebar
      ref="sidebar"
      :file-data="fileData"
      :index="index"
      :objects="objects"
      @refresh="refresh"
    />
  </b-overlay>
</template>

<script>

import { BButton, BOverlay } from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import LazyScroll from '@components/LazyScroll/LazyScroll.vue'
import empty from '@components/empty.vue'
import fileSidebar from './sidebar/fileSidebar.vue'

export default {
  components: {
    BButton,
    BOverlay,
    LazyScroll,
    fileSidebar,
    empty,
  },
  props: {
    list: {
      type: Boolean,
      default: false,
    },
    objects: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      fileData: {},
      loading: false,
      index: null,
    }
  },
  computed: {
    ...mapState('fileManagement', ['debtFiles', 'filters']),
  },
  methods: {
    ...mapActions('fileManagement', ['FETCH_DOC_ATTACH', 'FETCH_DEBT_FILES']),

    async refresh() {
      const params = {
        received_from: this.filters.received_from,
        responsible: this.filters.responsible,
        doc_status: this.filters.doc_status,
        title: this.filters.title?.id,
      }

      try {
        this.loading = true
        await this.FETCH_DEBT_FILES({
          object_id: this.objects.OBJECT_ID,
          content_type: this.objects.CONTENT_TYPE,
          ...params,
        })
      } catch (e) {
        this.loading = false
        throw e
      }
      this.loading = false
    },

    getDebtFiles() {
      const params = {
        content_type: this.objects.CONTENT_TYPE,
        object_id: this.objects.OBJECT_ID,
        page_size: 28,
        page: this.debtFiles.next,
        ...this.filters,
      }
      this.FETCH_DEBT_FILES(params)
    },

    openDetailFile(val, index) {
      this.FETCH_DOC_ATTACH({
        id: val.id,
        params: this.filters,
      })
        .then(() => {
          this.fileData = val
          this.index = index
          this.$refs.sidebar.sidebar = true
        })
    },
  },
}
</script>
