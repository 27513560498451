<template>
  <div>
    <b-button
      v-if="!file && !detailFile"
      variant="secondary"
      class="mb-1"
      @click="$emit('backToFile')"
    >
      <feather-icon icon="CornerLeftUpIcon" />
      Назад
    </b-button>

    <detailFilesDetail
      v-if="detailFile"
      :list="list"
      :organization="organization"
      @fileDetail="fileDetail"
    />

    <div
      v-else
      :class="!list ? '' : 'd-flex flex-wrap'"
    >
      <LazyScroll
        v-if="skDebtList.count"
        :other-params="{
          page_size: 5,
        }"
        :class="!list ? '' : 'd-flex flex-wrap'"
        :store-fetch="getSkDebt"
        :store-list="skDebtList"
      >
        <div
          v-for="(item, index) in skDebtList.results"
          :key="index"
          :class="!list ? 'listStyle rounded p-50' : 'file__image p-50'"
          @click="openFile(item)"
        >
          <img
            class="img-fluid"
            :src="require('@/assets/file2.png')"
            alt="file-image"
          >

          <small>{{ item.DEBT_TYPE }}</small>
        </div>
      </LazyScroll>

      <p
        v-else
        class="empty__file"
      >
        <empty />
      </p>
    </div>
  </div>
</template>
<script>

import { mapActions, mapMutations, mapState } from 'vuex'
import detailFilesDetail from '@/modules/FileManagement/components/Files/detailFilesDetail.vue'
import { BButton } from 'bootstrap-vue'
import LazyScroll from '@components/LazyScroll/LazyScroll.vue'
import empty from '@components/empty.vue'

export default {
  components: {
    empty,
    LazyScroll,
    BButton,
    detailFilesDetail,
  },
  props: {
    list: {
      type: Boolean,
      default: false,
    },
    file: {
      type: Boolean,
      default: false,
    },
    activeBorrow: {
      type: Number,
      default: 0,
    },
    organization: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      detailFile: false,
      activeFileType: null,
    }
  },
  computed: {
    ...mapState('fileManagement', ['skDebtList', 'breadcrumb', 'filters']),
  },
  methods: {
    ...mapActions('fileManagement', ['FETCH_SK_DEBT_LIST', 'ADD_BREADCRUMB']),
    ...mapMutations('fileManagement', ['SET_STEP', 'SET_ACTIVEFILETYPE']),

    getSkDebt() {
      const req = {
        id: this.activeBorrow,
        params: {
          page_size: 28,
          page: this.skDebtList.next,
          ...this.filters,
        },
      }

      this.FETCH_SK_DEBT_LIST(req)
    },

    openFile(val) {
      this.SET_STEP(3)
      this.activeFileType = val.id
      this.SET_ACTIVEFILETYPE(val.id)

      this.detailFile = true
      if (!this.breadcrumb.some(crumb => crumb.id === val.id)) {
        this.ADD_BREADCRUMB(val)
      }
    },

    fileDetail() {
      this.detailFile = !this.detailFile

      if (!this.detailFile) {
        this.breadcrumb.splice(this.breadcrumb.length - 1, 1)
        this.SET_STEP(2)
      }
    },
  },
}
</script>
