<template>
  <div style="height: 580px" class="overflow-auto">
    <p v-if="borrowerList.count === 0" class="mb-0 position-absolute position-top-0" style="right: 50%; top: 20%">
      <empty />
    </p>

    <div v-if="file">
      <LazyScroll v-if="borrowerList.count && organization.COMPANY_ID" :other-params="{
        page_size: 5,
      }" :class="!list ? '' : 'd-flex flex-wrap'" :store-fetch="getBorrower" :store-list="borrowerList">
        <div v-for="(item, index) in borrowerList.results" :key="index" class="mr-2 p-50"
          :class="!list ? 'listStyle rounded' : 'file__image'" @click="detailPage(item)">
          <b-img class="img-fluid" :src="require('@/assets/file2.png')" alt="file-image" />

          <small>{{ item.FULL_NAME }}</small>
        </div>
      </LazyScroll>
    </div>

    <detailFile v-else :list="list" :file="file" :organization="organization" :active-borrow="activeBorrow"
      @backToFile="$emit('backToFile')" />

    <!--  right bottom two buttons -->
    <div class="position-absolute position-right-0 position-bottom-0">
      <b-button size="sm" class="p-50 rounded-0" :variant="!list ? 'success' : ''" @click="changeType(false)">
        <feather-icon icon="AlignJustifyIcon" />
      </b-button>

      <b-button size="sm" class="p-50 rounded-0" :variant="list ? 'success' : ''" @click="changeType(true)">
        <feather-icon icon="AirplayIcon" />
      </b-button>
    </div>
  </div>
</template>

<script>
import {
  BImg,
  BButton,
} from 'bootstrap-vue'
import { mapActions, mapMutations, mapState } from 'vuex'
import LazyScroll from '@components/LazyScroll/LazyScroll.vue'
import empty from '@components/empty.vue'
import detailFile from './detailFiles.vue'

export default {
  components: {
    empty,
    BImg,
    BButton,
    LazyScroll,
    detailFile,
  },
  props: {
    file: {
      type: Boolean,
      default: false,
    },
    nextBr: {
      type: Boolean,
      default: false,
    },
    borrowId: {
      type: Number,
      default: () => 0,
    },
    organization: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      list: true,
      activeBorrow: null,
    }
  },
  computed: {
    ...mapState('fileManagement', ['borrowerList', 'skDebtList', 'breadcrumb', 'filters', 'params']),
  },
  mounted() {
    this.getListFromLocalStorage()
  },
  methods: {
    ...mapActions('fileManagement', ['FETCH_BORROWER_LIST', 'ADD_BREADCRUMB']),
    ...mapMutations('fileManagement', ['SET_ACTIVEBORROW', 'SET_BORROW_SPIN', 'SET_BORROW_SPIN']),

    async getBorrower() {
      if (this.nextBr) {
        try {
          this.SET_BORROW_SPIN(true)
          const req = {
            id: this.borrowId,
            params: this.toQueryParams({
              page_size: 15,
              page: this.borrowerList.next,
              ...this.params
            })
          }
          await this.FETCH_BORROWER_LIST(req)
        } catch (e) {
          this.SET_BORROW_SPIN(false)
          await this.$_errorToast(e)
        }
        this.SET_BORROW_SPIN(false)
      }
    },

    async detailPage(value) {
      try {
        this.SET_BORROW_SPIN(true)
        this.SET_ACTIVEBORROW(value.BORROWER)
        this.skDebtList.results = []
        this.activeBorrow = value.BORROWER

        this.$emit('backToFile')
        if (!this.breadcrumb.some(crumb => crumb.BORROWER === value.BORROWER)) {
          this.ADD_BREADCRUMB(value)
        }
      } catch (e) {
        await this.$_errorToast(e)
        this.SET_BORROW_SPIN(false)
      }
      this.SET_BORROW_SPIN(false)
    },

    changeType(value) {
      this.list = value
      localStorage.setItem('list', value)
    },

    getListFromLocalStorage() {
      const storedList = localStorage.getItem('list')
      this.list = JSON.parse(storedList)
    },

    toQueryParams(params) {
      const stepFunctions = {
        1: this.SET_EMPTY_BORROWER,
        2: this.CLEAR_SK_DEBT_LIST,
        3: this.SET_EMPTY_DEBTFILE,
        4: this.SET_EMPTY_DEBT_FILES
      };

      const stepFunction = stepFunctions[this.step];
      if (stepFunction) {
        stepFunction();
      }
      const searchParams = new URLSearchParams();
      searchParams.append('page', params.page || '')
      searchParams.append('page_size', params.page_size || '')
      Object.entries(params).forEach(([key, values]) => {
        if (Array.isArray(values)) {
          if (values.length) {
            values.forEach((value) => {
              searchParams.append(key, value);
            });
          }
        }


        if (key.includes('search')) {
          searchParams.append('search', params.search_document || '')
        }
      });

      if (Object.keys(params).some(key => key.includes('date'))) {
        searchParams.append('created_date', params.date?.start || '')
        searchParams.append('end_date', params.date?.end || '')
      }

      return searchParams;
    }
  },
}
</script>
