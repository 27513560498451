<template>
  <div>
    <v-select
      v-if="opeennene"
      v-model="selected"
      :label="label"
      :reduce="reduce"
      :options="options.results || options.EXECUTIVE_WORK"
      :get-option-label="getOptionLabel"
      :placeholder="placeholder"
      :multiple="multiple"
      :disabled="disabled"
      :close-on-select="closeOnSelect"
      @close="onClose"
      @search="fetchOptions"
      @input="onSelected"
      @open="onOpen"
    >
      <template #list-footer>
        <li
          v-show="options.next"
          ref="load"
          class="loader"
        >
          Загрузка...
        </li>
      </template>
    </v-select>
  </div>
</template>

<script>
import VSelect from 'vue-select'

export default {
  components: {
    VSelect,
  },
  props: {
    state: {
      type: [Object, Array],
    },
    disabled: {
      type: Boolean,
      default() {
        return false
      },
    },
    closeOnSelect: {
      type: Boolean,
      default() {
        return true
      },
    },
    autoFetch: {
      type: Boolean,
      default() {
        return true
      },
    },
    label: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    options: {
      type: Object,
      required: false,
      default() {
        return {
          next: null,
          timer: null,
        }
      },
    },
    getOptionLabel: {
      type: Function,
      required: false,
    },
    reduce: {
      type: Function,
      required: false,
      default: v => v,
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    lazyFetchData: {
      type: Function,
      required: false,
    },
    pageSize: {
      type: Number,
      required: false,
      default: 15,
    },
    search: {
      type: Function,
      required: false,
      default() {
        return new Promise((resolve, reject) => {
          setTimeout(() => resolve([]), 100)
        })
      },
    },
    value: {
      type: [Array, Object, String, Number],
    },
    filters: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      observer: null,
      selectLoading: false,
      localOptions: [],
      searchText: '',
      selected: null,
      opeennene: true,
    }
  },
  watch: {
    value(newValue) {
      this.selected = newValue
    },
    searchText: {
      handler(val) {
        if (!val) {
          this.localOptions = []
          this.options.results = []
          this.opeennene = false
          // this.lazyFetchData({ page: 1, page_size: 15 })
          setTimeout(() => {
            this.opeennene = true
          }, 10)
        }
      },
    },
  },

  mounted() {
    this.getObserver()
  },
  methods: {
    getObserver() {
      if (!(this.options.results && this.options.results.length) && this.autoFetch) {
        this.options.results = []
        this.lazyFetchData({ ...this.filters, page: this.options.next, page_size: 15 })
      }
      this.observer = new IntersectionObserver(async ([{ isIntersecting, target }]) => {
        if (!isIntersecting || this.selectLoading) {
          return
        }
        if (!this.options.next) {
          return
        }
        this.selectLoading = true
        const ul = target.offsetParent
        const { scrollTop } = target.offsetParent

        if (this.options.next) {
          await this.lazyFetchData({
            ...this.filters,
            page: this.options.next,
            page_size: this.pageSize,
          })
        }
        this.selectLoading = false
        await this.$nextTick()
        ul.scrollTop = scrollTop
      })
      this.selected = this.value
    },

    onOpen() {
      this.$nextTick(() => {
        if (this.$refs.load) {
          this.observer.observe(this.$refs.load)
        }
      })
      // console.clear()
    },

    onSelected(value) {
      this.$emit('input', value)
    },

    onClose() {
      this.observer.disconnect()
    },

    async fetchOptions(val, loading) {
      // console.clear()
      this.searchText = val
      this.page = 1

      if (this.timer) {
        clearTimeout(this.timer)
      }

      loading(true)

      try {
        await new Promise(resolve => {
          this.timer = setTimeout(resolve, 500)
        })

        // if (!this.searchText) {
        //   console.log(this.searchText, 'ds')
        //   this.options.results = []
        //   loading(false)
        //   return
        // }
        const response = await this.lazyFetchData({ ...this.filters, search: val, page_size: 15 })

        if (response && response.results && response.results[0]) {
          this.options.results = response.results
          this.getObserver()
        } else {
          this.options.results = []
          this.lazyFetchData({ ...this.filters, search: val, page_size: 15 })
            .then(res => {
              this.options.results = res.results
            })
        }
      } catch (error) {
        console.error('Error fetching options:', error)
      } finally {
        loading(false)
      }
    },
  },
}
</script>

<style scoped>
.loader {
  text-align: center;
  color: #bbbbbb;
}
</style>
