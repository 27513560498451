<template>
  <div>
    <b-button
      v-if="!utilsFiles"
      variant="secondary"
      class="mb-1"
      @click="$emit('fileDetail')"
    >
      <feather-icon icon="CornerLeftUpIcon" />
      Назад
    </b-button>

    <div
      v-if="!utilsFiles"
      :class="!list ? '' : 'd-flex flex-wrap'"
    >
      <!--OBJECT-->
      <div
        v-if="debtFileTypes.ID"
        :class="!list ? 'listStyle rounded p-50' : 'file__image p-50'"
        @click="openDo"
      >
        <img
          class="img-fluid"
          :src="require('@/assets/file2.png')"
          alt="file-image"
        >
        <small>{{ debtFileTypes.TITLE }}</small>
      </div>

      <!--ARRAY EXECUTIVE_WORK-->
      <div
        v-for="(item, index) in debtFileTypes.EXECUTIVE_WORK"
        :key="index"
        :class="!list ? 'listStyle rounded p-50' : 'file__image p-50'"
        @click="openDetailFile(item)"
      >
        <img
          class="img-fluid"
          :src="require('@/assets/file2.png')"
          alt="file-image"
        >
        <small>{{ item.TITLE }}</small>
      </div>
    </div>

    <Files
      v-if="utilsFiles"
      :list="list"
      :organization="organization"
      :objects="objects"
      @utilsFile="utilsFile"
    />
  </div>
</template>

<script>

import { BButton } from 'bootstrap-vue'
import { mapActions, mapMutations, mapState } from 'vuex'
import Files from './utilsFiles.vue'

export default {
  components: { BButton, Files },
  props: {
    list: {
      type: Boolean,
      default: false,
    },
    organization: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      utilsFiles: false,
      objects: {},
    }
  },
  computed: {
    ...mapState('fileManagement', ['debtFileTypes', 'debtFiles', 'breadcrumb']),
  },
  methods: {
    ...mapActions('fileManagement', ['ADD_BREADCRUMB']),
    ...mapMutations('fileManagement', ['SET_STEP', 'SET_DEBT_FILES']),

    async openDetailFile(val) {
      try {
        this.SET_STEP(4)
        this.debtFiles.results = []
        const req = {
          content_type: val.CONTENT_TYPE,
          object_id: val.OBJECT_ID,
        }
        this.objects = val
        this.SET_DEBT_FILES(req)

        this.utilsFiles = true
        if (!this.breadcrumb.some(crumb => crumb.ID === val.ID)) {
          await this.ADD_BREADCRUMB(val)
        }
      } catch (e) {
        await this.$_errorToast(e)
      }
    },

    async openDo() {
      try {
        this.SET_STEP(4)
        this.debtFiles.results = []
        const { CONTENT_TYPE, OBJECT_ID } = this.debtFileTypes
        const req = {
          content_type: CONTENT_TYPE,
          object_id: OBJECT_ID,
        }
        this.objects = { CONTENT_TYPE, OBJECT_ID }

        this.SET_DEBT_FILES(req)
        this.utilsFiles = true

        if (!this.breadcrumb.some(crumb => crumb.TITLE === this.debtFileTypes.TITLE)) {
          await this.ADD_BREADCRUMB(this.debtFileTypes)
        }
      } catch (e) {
        await this.$_errorToast(e)
      }
    },

    utilsFile() {
      this.utilsFiles = !this.utilsFiles

      if (!this.utilsFiles) {
        this.breadcrumb.splice(this.breadcrumb.length - 1, 1)
        this.SET_STEP(3)
      }
    },
  },
}
</script>
