<template>
  <b-breadcrumb
    v-if="company"
    class="d-flex justify-content-start align-items-center handle__bread p-0 mb-50 p-1 shadow rounded"
  >
    <b-breadcrumb-item>
      <FeatherIcon
        icon="HomeIcon"
        size="16"
      />
    </b-breadcrumb-item>

    <b-breadcrumb-item>
      {{ company }}
    </b-breadcrumb-item>

    <b-breadcrumb-item
      v-for="(br, index) in breadcrumb"
      :key="index"
    >
      <template v-if="br.FULL_NAME || br.DEBT_TYPE || br.TITLE">
        {{ br.FULL_NAME || br.DEBT_TYPE || br.TITLE }}
      </template>
    </b-breadcrumb-item>
  </b-breadcrumb>
</template>

<script>
import { BBreadcrumb, BBreadcrumbItem } from 'bootstrap-vue'
import { mapMutations, mapState } from 'vuex'

export default {
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
  },
  props: {
    company: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState('fileManagement', ['breadcrumb']),
  },
  watch: {
    $route: {
      immediate: true,
      handler(val) {
        if (val.path !== 'file-management') {
          this.SET_ARRAY()
        }
      },
    },
  },
  methods: {
    ...mapMutations('fileManagement', ['SET_ARRAY']),
  },
}
</script>
<style>
.handle__bread .breadcrumb-item a {
  color: #6e6b7b !important;
  cursor: default;
}

.dark-layout .handle__bread {
  background-color: #283046;
}

.handle__bread {
  background-color: #fff;
}
</style>
